// i18next-extract-mark-ns-start products-vectis-complex

import { VECTIS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisPostProcessingPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							Configurable data acquisition from simulations and versatile user-friendly
							post-processing is paramount for collection and management of
							complex data simulations. VECTIS makes the task of instrumenting
							simulations and analysing the data simple through :
						</p>
						<ul>
							<li>Point, Surface and Volume data acquisition sensors with fully
								configurable access to flow, spray and combustion variables</li>
							<li>Tailored surface sensors for spray analysis and post-processing</li>
							<li>Versatile toolbox for management and manipulation of 3D post-processing files</li>
							<li>R-Post – a fast 1D/2D plotting solution with a powerful Python® interface</li>
							<li>R-Viewer – a powerful parallel 3D post-processor with advanced R-Desk scripting and Python® capabilities</li>
							<li>Export of 3D data to portable formats allowing analysis in third-party software</li>
						</ul>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="VECTIS H2 DI engine" />
							<Trans i18nKey="small"><small>Injection and flame propagation in an H2 DI engine</small></Trans>
						</p>
					)}
				</div>

				<Aside>
					<Aside.Widget
						title="Capabilities and applications"
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductVectisPostProcessingPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis-complex", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_H2_DI.png" }) {
		...imageBreaker
	}
}
`;

